import Flickity from 'flickity'

var carousels = document.querySelectorAll('.main-carousel');

if (carousels) {
  carousels.forEach((elem) => {
    var flkty = new Flickity( elem, {
      // options
      cellAlign: 'left',
      contain: false,
      wrapAround: true,
      autoPlay: 2500,
    });
  });
}
