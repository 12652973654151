import Vue from 'vue'

if(!!document.getElementById("modalSection")) {
  const Modal = {
    name: 'modal',
    template: '#modal',
    methods: {
      close(event) {
        this.$emit('close');
      },
    },
  };

  new Vue({
    el: '#modalSection',
    name: 'app',
    components: {
      modal: Modal,
    },
    data () {
      return {
        isModalVisible: true,
      }
    },
    methods: {
      showModal() {
        this.isModalVisible = true
      },
      closeModal() {
        this.isModalVisible = false
      }
    },
  })
}