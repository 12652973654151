import Vue from 'vue'

if(!!document.getElementById("primary-navigation")) {
  // Primary Navigation
  const offsetValue = 60

  const primaryNavigation = new Vue({
    name: 'primary-navigation',
    el: '#primary-navigation',
    data: {
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      open: false,
      searchActive: false
    },
  
    mounted () {
      this.lastScrollPosition = window.pageYOffset
      window.addEventListener('scroll', this.onScroll)
    },
  
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
    },
  
    methods: {
      onScroll () {
        if (window.pageYOffset < 0) {
          return
        }
        if (Math.abs(window.pageYOffset - this.lastScrollPosition) < offsetValue) {
          return
        }
        this.showNavbar = window.pageYOffset < this.lastScrollPosition
        this.lastScrollPosition = window.pageYOffset
      },
      toggleNavigation() {
        this.open = !this.open

        // Disable content scroll when navigation is open
        document.body.classList.toggle('overflow-hidden');
      },
      toggleSearch() {
        this.searchActive = !this.searchActive
      }
    }
  })
}