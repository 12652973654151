//
// Article slider
//

import Swiper from 'swiper';

const articleSlider = function() {
  const swiper = new Swiper('.article-slider', {
    slidesPerView: 1.5,
    spaceBetween: 16,
    freeMode: true,
    breakpoints: {
      1280: {
        slidesPerView: 4.5,
        spaceBetween: 48
      },
      768: {
        slidesPerView: 3.5,
        spaceBetween: 32
      },
      640: {
        slidesPerView: 2.5,
      }
    }
  });
};

articleSlider();