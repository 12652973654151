let headerEl = document.querySelector(".main-nav-outer")

window.addEventListener("scroll", (e) => {
    if(window.scrollY > 0) {
        if(!headerEl.classList.contains("nav-white")) {
            headerEl.classList.add("nav-white")
        }
    } else if(window.scrollY === 0) {
        headerEl.classList.remove("nav-white")
    }
})
